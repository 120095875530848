.App {
    text-align: center;
}

.copy * {
    width: 0;
    height: 0;
}

/*input {*/
    /*width: 50px;*/
    /*margin-left: 5px;*/
/*}*/

/*label:first-child {*/
    /*padding-right: 15px;*/
/*}*/

/*.group {*/
    /*padding: 5px;*/
/*}*/
